:root {
  --day-background: white;
  --day-button: #d2ceff;
  --day-article: #f1f1f1;
  --day-input-background: #d3d3d3;
  --day-input-text: #474748;
  --day-input-placeholder: #7c7c7c;
  --day-text: #4f5053;
  --night-background: #26272b;
  --night-button: #2196f3;
  --night-link: #57bffc;
  --night-input-background: #202021;
  --night-article: #313237;
  --night-text: #b5b5b5;
  --day-box-shadow: 0 2.8px 2.2px rgba(0, 0, 0, 0.034), 0 6.7px 5.3px rgba(0, 0, 0, 0.048), 0 12.5px 10px rgba(0, 0, 0, 0.06), 0 22.3px 17.9px rgba(0, 0, 0, 0.072), 0 41.8px 33.4px rgba(0, 0, 0, 0.086), 0 100px 80px rgba(0, 0, 0, 0.12);
}

#root {
  width: 100%;
  height: 100%;
}

:focus-visible {
  outline: none;
}

* {
  font-family: "Lexend Deca";
}

html {
  height: 100%;
}

body {
  height: 100%;
  margin: 0;
  line-height: 1.5;
  overflow: hidden;
  user-select: none;
}

a {
  text-decoration: none;
  color: white;
}

.day a {
  color: #3673ef;
}

a:hover {
  color: var(--night-link);
  transition: 0.3s;
}

h1, h2, h3, h4, h5, h6 {
  margin: 1rem 0;
  font-weight: normal;
}

h1 {
  font-size: 1.75rem;
}

p {
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 300;
}

main {
  width: 100%;
  height: calc(100% - 4.5rem);
  background-color: var(--night-background);
  color: white;
}

form > section:first-of-type {
  margin-top: 0;
}

form > section {
  margin-top: 1rem;
}

form > section > select {
  user-select: none;
}

.day input[type="text"]:read-only, input[type="password"]:read-only, input:disabled {
  background-color: #e6e6e6;
  border-color: #e6e6e6;
  color: #474748;
}

input[type="text"]:read-only, input[type="password"]:read-only, input:disabled {
  color: gray;
  cursor: not-allowed;
  background-color: #474748;
  border-color: #474748;
  box-shadow: none;
}

.day form input[type="text"], .day form input[type="password"], .day form input[type="email"] {
  background-color: var(--day-input-background);
  border-color: var(--day-input-background);
  color: var(--day-input-text);
}

.day form ::placeholder {
  color: var(--day-input-placeholder);
}

textarea, form input[type="text"], form input[type="password"], form input[type="email"], .input-with-prefix {
  padding: 0.6rem;
  width: 100%;
  background-color: var(--night-input-background);
  border: 1px solid var(--night-input-background);
  color: white;
  border-radius: 5px;
  font-size: 1rem;
  box-sizing: border-box;
}

textarea {
  height: 10rem;
  resize: none;
  font-weight: 300;
}

.input-with-prefix {
  display: flex;
  cursor: text;
  padding: 0;
}

.input-with-prefix span {
  color: var(--night-text);
  padding: 0.6rem 0 0.6rem 0.6rem;
}

.input-with-prefix > input[type="text"] {
  border: none;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
}

input[type="checkbox"] {
  width: 25px;
  height: 25px;
}

input[type="checkbox"] + label {
  display: inline;
  position: relative;
  bottom: 7px;
  left: 7px;
}

label {
  display: block;
  margin-bottom: 1rem;
}

label > button {
  border-radius: 100%;
  border: 1px solid;
  color: gray;
  margin-left: 0.5rem;
  user-select: none;
  cursor: pointer;
  background-color: rgb(239, 239, 239);
  padding: 0;
  height: 25px;
  width: 25px;
}

label > button:hover {
  background-color: rgb(239, 239, 239);
}

/* Header */
header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4.5rem;
  padding: 0 1.2rem;
  width: calc(100% - 2.4rem);
  z-index: 4;
  color: white;
  background-color: #26272b;
  filter: drop-shadow(0 10px 12px rgba(0, 0, 0, 0.2));
}

header.day, main.day {
  background-color: white;
  color: #26272b;
}

header > section {
  margin: 0;
  display: flex;
  align-items: center;
}

header > section:first-of-type > section a {
  margin-left: 1rem;
  font-size: 1.1rem;
  position: relative;
  top: 2px;
}

.day button, .day input[type="button"], .day input[type="submit"] {
  background-color: var(--day-button);
}

button, input[type="button"], input[type="submit"] {
  cursor: pointer;
  background-color: var(--night-button);
  border: none;
  padding: 0.6rem 1rem;
  color: white;
  font-size: 1rem;
  transition: 0.1s;
  border-radius: 5px;
}

.day button:hover, .day input[type="button"]:hover, .day input[type="submit"]:hover {
  background-color: #b1acec;
}

button:hover, input[type="button"]:hover, input[type="submit"]:hover {
  filter: brightness(.8);
}

input[type="password"] {
  letter-spacing: 2px;
}

.unavailable {
  background-color: #6e6e6e !important;
  cursor: not-allowed !important;
}

.invalid {
  border-color: red !important;
}

.invisible {
  display: none;
}

article {
  user-select: text;
}

.day b {
  color: black;
}

b {
  color: white;
}

u {
  text-decoration: none;
  border-bottom: 2px solid;
}

button {
  border-radius: 5px;
}

li {
  margin-top: 1rem;
}

p, li {
  color: var(--night-text);
}

.day p, .day li {
  color: var(--day-text);
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: transparent;
}

.day ::-webkit-scrollbar-track {
  background-color: rgb(212, 212, 212);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #5c5c5c;
}

.day ::-webkit-scrollbar-thumb {
  background-color: #999999;
}

.day ::-webkit-scrollbar-thumb:hover {
  background-color: #7a7a7a;
}

footer {
  border-top: 1px solid #5b5e62;
  padding: 2rem 0;
  margin: 4rem 5rem;
  margin-bottom: 0;
}

footer a {
  border-bottom: none;
  color: white;
}

footer a:hover {
  font-weight: normal;
  color: #c3d8ff;
  transition: 0.3s;
}

button.destructive {
  background-color: #f65151;
}